<template>
    <div id="zhBookInfo">
      <div class="info_top">
        <img v-lazy="data.img" alt/>
      </div>
      <div class="collect">
       <div  style="margin-right:0.5rem;" v-if="showPdf"
             @click="showSelectPdf">
         <img src="@/assets/img/pdf.png" alt />
       </div>
      </div>
      <div class="title_zh" v-show="data.name">{{ data.name }}</div>
      <div class="book_tabr">
        <cube-tab-bar
            :showSlider="true"
            v-model="selectedLabelDefault"
            :data="tabs"
            @click="clickHandler"
            @change="changeHandler"></cube-tab-bar>

        <div class="infos" v-if="show_info">
          <div class="info_content">
            <img src="../../assets/img/zh_info6.png" alt/>
            <div class="titles">名称：</div>
            <div class="content">{{ data.name|nodata }}</div>
          </div>
          <div class="info_content">
            <img src="../../assets/img/isnb.png" alt/>
            <div class="titles">ISBN号：</div>
            <div class="content">{{ data.isbn|nodata }}</div>
          </div>
          <div class="info_content">
            <img src="../../assets/img/zh_info1.png" alt/>
            <div class="titles">作者：</div>
            <div class="content">{{ data.author|nodata }}</div>
          </div>
          <div class="info_content">
            <img src="../../assets/img/zh_info2.png" alt/>
            <div class="titles">出版社：</div>
            <div class="content">{{ data.press_name|nodata }}</div>
          </div>

          <div class="info_content">
            <img src="../../assets/img/zh_info3.png" alt/>
            <div class="titles">出版时间：</div>
            <div class="content">{{ data.publish_date_chu|nodata }}</div>
          </div>
          <div class="info_content">
            <img src="../../assets/img/zh_info4.png" alt/>
            <div class="titles">页数：</div>
            <div class="content">{{ data.page|nodata }}</div>
          </div>
          <div class="info_content">
            <img src="../../assets/img/zh_info4.png" alt/>
            <div class="titles">总字数：</div>
            <div class="content">{{ data.word_count|nodata }}</div>
          </div>
          <div class="info_content">
            <img src="../../assets/img/zh_info5.png" alt/>
            <div class="titles">价格：</div>
            <div class="content">{{ data.price|nodata }}</div>
          </div>
          <div class="info_content">
            <img src="../../assets/img/zh_info7.png" alt/>
            <div class="titles">简介：</div>
          </div>
          <div class="contents" v-html="htmlFilter(data.content_lang?data.content_lang.zh:'')"></div>
        </div>

        <div class="infos" v-if="show_library">
          <div class="libraries" v-show="item.name" v-for="(item,index) in librarys" :key="index">
            <div
                ref="lib"
                :id="item.roomname?item.roomname.room_id:''"
                class="library_son"
                @click="selected(item)">
              <div class="left">{{ item.libraryname.zh ? item.libraryname : '' }}:</div>
              <div class="right" v-if="item.roomname">
                <span>{{ item.roomname.name }}</span>
              </div>
            </div>
          </div>
          <div class="info_content">
            <img src="../../assets/img/zh_info1.png" alt/>
            <div class="titles">图书馆：</div>
            <div class="content" v-if="infosData">{{ infosData.library_name_lang|nodata }}</div>
          </div>
          <div class="info_content">
            <img src="../../assets/img/zh_info2.png" alt/>
            <div class="titles">阅览室：</div>
            <div class="content" v-if="infosData">{{ infosData.name_lang }}</div>
          </div>
          <div class="info_content" v-if="infosData.other&&infosData.other.length&&infosData.other[0].call_num">
            <img src="../../assets/img/isnb.png" alt/>
            <div class="titles">索书号：</div>
            <div class="content">
              <span v-for="(item,index) in infosData.other" :key="index">{{ item.call_num ? item.call_num : '' }}</span>
            </div>
          </div>
          <div class="info_content" v-if="infosData.other&&infosData.other.length&&infosData.other[0].bar_code">
            <img src="../../assets/img/zh_info4.png" alt/>
            <div class="titles">条形码：</div>
            <div class="content">
              <span v-for="(item,index) in infosData.other" :key="index">{{ item.bar_code ? item.bar_code : '' }}</span>
            </div>
          </div>
          <!--        <div class="info_content" v-if="infosData.marc && infosData.marc!='0'">-->
          <!--          <img src="../../assets/img/zh_info4.png" alt/>-->
          <!--          <div class="titles">marc记录号：</div>-->
          <!--          <div class="content">{{ infosData.marc|nodata }}</div>-->
          <!--        </div>-->
        </div>
        <div class="imgs" v-if="show_img">
          <cube-slide ref="slide" :data="items" :auto-play="false">
            <cube-slide-item v-for="(item, index) in items" :key="index">
              <div class="box_cub_img" @click="showImagePreview(index)">
                <img :src="item.images" />
              </div>
            </cube-slide-item>
          </cube-slide>
        </div>
        <div class="mulu" v-if="show_mulu">
          <books-index-zh></books-index-zh>
        </div>
      </div>
      <!--    查看pdf-->
      <lay-file-select-zh
          :TypeName="TypeName"
          @maskClick="maskClick"
          @isClose="closeAlert"
          :IsShow="confirm.IsShow"
          :fileType="fileType"
          @onClick="selected1"
          :option="fileList"></lay-file-select-zh>
    </div>
</template>

<script>
export default {
  name: 'inforindex_zhName',
  components: {
    BooksIndexZh: re => require(['@/components/books_index_zh.vue'], re),
  },
  data() {
    return {
      keep_type: "1",
      getInfo: false,
      poster: null,
      audio: {
        name: "",
        autoplay: false,
        loop: true,
        url: "",
        // https://fengxianqi.github.io/v-audio-player/dist/media/sometimes_when_we_touch.010fafb2.mp3
      },
      video: {
        autoplay: false,
        loop: true,
        url: "",
        // http://s2.pstatp.com/cdn/expire-1-M/byted-player-videos/1.0.0/xgplayer-demo.mp4
      },
      TypeName: "",
      fileType: 1,
      confirm: {
        IsShow: false,
      },
      showVideo: false,
      showMusic: false,
      showPdf: false,
      fileList: [],
      pdfList: [],
      videoList: [],
      musicList: [],
      componentId: "Books_Intro",
      items: [],
      imgs: [],
      show_info: true,
      show_img: false,
      show_library: false,
      show_mulu: false,
      book_id: "",
      data: {},
      selectedLabelDefault: "详情",
      tabs: [
        {
          label: "详情",
        },
        {
          label: "目录",
        },
        {
          label: "馆藏信息",
        },
        {
          label: "更多",
        },
      ],
      queryData: {
        book_id: "",
        library_id: [],
      },
      librarys: [],
      infosData: {},
      id: ''
    };
  },
  methods: {
    //点击pdf
    showSelectPdf() {
      (this.TypeName = "ᠡᠬᠢ ᠰᠤᠷᠪᠤᠯᠵᠢ"),
          (this.fileType = 1),
          (this.fileList = this.pdfList);
      this.confirm.IsShow = true;
    },
    maskClick() {
      this.confirm.IsShow = false;
    },
    selected1(item) {
      if (item.type == 1) {
        if (item.lx == 1) {
          this.$router.push({
            path: "/zhPdfView",
            query: {
              url: item.url,
            },
          });
          sessionStorage.setItem('pdfOpen', true)
        } else if (item.lx == 2) {
          this.$router.push({
            path: "/h5Iframe",
            query: {
              url: item.url,
            },
          });
        }

      } else if (item.type == 2) {
        //音频
        this.audio.url = item.url;
        this.showMusicPlayer();
      } else if (item.type == 3) {
        //视频
        this.video.url = item.url;
        this.poster = item.videopic;
        this.showVideoPlayer();
      }
    },
    closeAlert() {
      this.confirm.IsShow = false;
    },
    selected(item) {
      const that = this;
      that.$nextTick(() => {
        that.$refs.lib.forEach((item1, index) => {
          that.$refs.lib[index].classList.remove("active");
          if (item1.id == item.roomname ? item.roomname.room_id : '') {
            that.$refs.lib[index].classList.add("active");
          } else if (item1.title == item.library_id) {
            that.$refs.lib[index].classList.add("active");

          }
        })
      });
      that.infosData = {};
      if (item) {
        console.log('item', item)
        this.infosData.library_name_lang = item.libraryname.zh
            ? item.libraryname.zh
            : item.libraryname.mn
                ? item.libraryname.mn
                : "暂无信息";
        this.infosData.name_lang = item.roomname ? item.roomname.name_lang.zh
            ? item.roomname.name_lang.zh
            : item.roomname.name_lang.mn
                ? item.libraryname.mn
                : "暂无信息" : "暂无信息";
        this.infosData.other = item.other
        that.$forceUpdate();
      }
    },
    showImagePreview(index) {
      this.initialIndex = index;
      const params = {
        $props: {
          imgs: this.imgs, //存当前图片的数组，如果是渲染的每一项，通过形参传进来
          initialIndex: "initialIndex", //把图片数组循环后，图片的索引
          loop: false,
        },
        $events: {
          change: (i) => {
            // 必须更新 initialIndex
            this.initialIndex = i;
          },
        },
      };
      this.$createImagePreview({
        ...params,
      }).show();
    },
    clickHandler(label) {
      if (label === "详情") {
        this.show_info = true;
        this.show_img = false;
        this.show_library = false;
        this.show_mulu = false;
      } else if (label === "目录") {
        this.show_info = false;
        this.show_library = false;
        this.show_img = false;
        this.show_mulu = true;
      } else if (label === "馆藏信息") {
        this.show_info = false;
        this.show_library = true;
        this.show_img = false;
        this.show_mulu = false;
        this.selected(this.librarys[0]);
      } else if (label === "更多") {
        this.show_info = false;
        this.show_library = false;
        this.show_img = true;
        this.show_mulu = false;
      }
    },
    changeHandler(label) {
    },
    infoData() {
      const that = this;
      let condition = {
        book_id: that.$route.query.id,
        library_id: that.library_id,
        annexboolean: true
      }
      that.infosData = {};

      that.$http.get(`api/web/v1/h5/find`, {params: condition})
          .then((val) => {
            console.log(val, '详情')
            //基础信息
            that.infosData = {};
            that.data = val.data;
            that.data.img = val.data.img;
            if (val.data.name_lang.mn) {
              that.data.name_lang = val.data.name_lang.mn;
            } else {
              that.data.name_lang = "";
            }
            that.data.name_lang_zh = val.data.name_lang.zh
                ? val.data.name_lang.zh
                : val.data.name_lang.mn
                    ? val.data.name_lang.mn
                    : "";
            if (val.data.name) {
              that.data.name = val.data.name;
            } else {
              that.data.name = "";
            }
            //pdf
            val.data.annex.forEach((item) => {
              if (item.type == 1) {
                that.pdfList.push({
                  type: item.type,
                  url: item.url,
                  name: item.name,
                  size: item.size,
                  videopic: item.videopic,
                  voratime: item.voratime,
                  lx: item.lx
                });
                if (that.pdfList.length == 0) {
                  that.showPdf = false;
                } else {
                  that.showPdf = true;
                }
              }
            })
            //馆藏信息
            this.librarys = val.data.libraryotherlist
            console.log('this.librarys', this.librarys)
            let datas = this.librarys[0];
            this.infosData.library_name_lang = datas.libraryname.zh
                ? datas.libraryname.zh
                : datas.libraryname.mn
                    ? datas.libraryname.mn
                    : "暂无信息";


            this.infosData.name_lang = datas.roomname ? datas.roomname.name_lang.zh
                ? datas.roomname.name_lang.zh
                : datas.name_lang.mn
                    ? datas.roomname.library_name_lang.mn
                    : "暂无数据" : "暂无数据";

            this.infosData.other = datas.other

            // 更多
            let a = {
              images: val.data.img,
            };
            that.imgs.push(val.data.img);
            that.items.push(a);
            val.data.book_img.forEach((item) => {
              let b = {
                images: item.url,
              };
              that.imgs.push(item.url);
              that.items.push(b);
            });
            // that.$nextTick(() => {
            // 	that.$refs.lib[0].classList.add("active");
            // });
            that.keep_type = val.data.keep_type;
            that.showToastMask(false);
          }).catch((err) => {
        that.showToastMask(false);
      });
    },


  },
  computed: {
    // 是否显示资源
    annexboolean() {
      return this.$store.getters.annexboolean
    },
    // 用户id
    member_id() {
      let mm = JSON.parse(localStorage.getItem("userInfoMemberId")) ? JSON.parse(localStorage.getItem("userInfoMemberId")).member_id : ''
      return mm
    },
    // 馆藏id
    library_id() {
      let vv = localStorage.getItem("theme_color") ? JSON.parse(localStorage.getItem("theme_color")).library_id : "";
      return vv
    },
  },
  watch: {
    '$store.getters.annexboolean': function (newValue, oldValue) {
      if (newValue != oldValue) {
        this.getInfo = true
        this.infoData();
      }
    },
  },
  created() {
    this.infoData()
  },
  mounted() {},
  beforeRouteLeave(to, from, next) {
    if (to.path !== '/PdfView' || to.path !== '/LiteratureContent') {
      sessionStorage.setItem('pdfOpen', false)
    }
    if(to.path != '/zhPdfView'&&to.path != '/h5Iframe'){
      localStorage.removeItem("youmuBook_index");
    }
    next();
  },
  beforeRouteEnter(to, from, next) {
    if(from.path !='/zhPdfView'&&from.path != '/h5Iframe'){
      localStorage.removeItem("youmuBook_index");
    }
    if (from.path == '/h5Iframe') {
      next(vm => {
        vm.infoData()
      });
    } else {
      next()
    }
  },
};
</script>

<style lang="less">
#zhBookInfo {
  height: 100%;
  overflow-y: auto;
  position: relative;

  .collect {
    position: absolute;
    right: 20px;
    top: 70px;
    display: flex;
    flex-direction: column;

    img {
      margin-top: 10px;
      width: 2.6rem;
      height: 2.6rem;
    }
  }

  .active {
    color: #fff;

    p {
      color: #fff;

      span {
        color: #fff;
      }
    }

    background-color: var(--themeColor);
  }

  .info_top {
    padding-top: 10px;
    background-color: #fff;

    img {
      width: 104px;
      height: 130px;
      display: block;
      margin: 0 auto;
    }
  }

  .title {
    padding-top: 15px;
    padding-left: 20px;
    width: 100%;
    background-color: #fff;
    font-size: 20px;
    height: 100px;
    color: #666;
  }

  .title_zh {
    padding-top: 7px;
    padding-left: 20px;
    width: 100%;
    background-color: #fff;
    font-size: 20px;
    min-height: 50px;
    color: #666;
  }

  .book_tabr {
    width: 100%;
    margin-top: 5px;
    padding-bottom: 1rem;
    background: #ededed;

    .cube-tab-bar {
      height: 45px;
      background-color: #fff;
    }

    .cube-tab_active {
      color: var(--themeColor);
    }

    .cube-tab {
      font-size: 16px;
    }

    .cube-tab-bar-slider {
      background-color: var(--themeColor);
    }
  }

  .infos {
    width: 100%;
    background-color: #fff;

    .contents {
      font-size: 16px;
      padding: 10px;
      line-height: 22px;
      text-indent: 20px;
    }

    .info_content {
      width: 100%;
      padding-left: 8px;
      background-color: #fff;
      min-height: 40px;
      display: flex;
      font-size: 16px;
      color: #666;
      align-items: center;
      border-bottom: 1px solid #ededed;

      img {
        width: 25px;
        height: 25px;
      }

      .titles {
        min-width: 50px;
        margin-left: 5px;
      }

      .content {
        padding: 5px 0;
        line-height: 22px;
      }
    }

    .libraries {
      margin-top: 2px;
      padding: 0 10px 5px 10px;
      font-size: 16px;
      width: 100%;
      display: flex;
      align-items: center;

      .library_son {
        float: left;
        padding-left: 10px;
        display: flex;
        align-items: center;
        border: 1px solid var(--themeColor);
        border-radius: 5px;
        margin-top: 5px;
        margin-bottom: 5px;
      }

      .left {
      }

      .right {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: row;
        flex-wrap: wrap;

        span {
          margin: 5px;
          padding: 0 5px;
          font-size: 14px;
          height: 30px;
          line-height: 30px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
    }
  }

  .imgs {
    .cube-slide-item {
      .box_cub_img {
        width: 70%;
        margin: 0 auto;
        img {
          width: 100%;
          display: block;
          margin: 0 auto;
          height: 100%;
        }
      }
    }

    .cube-slide-dots {
      bottom: 0;
    }

    // img{
    //   width: 100%;
    // }
  }
}
</style>
